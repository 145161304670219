:root {
  --field-label-color: #0d6efd; /* define primary color */
  --field-label-text-color: white; /* define primary color */

  --module-lable-color: #198754; /* define secondary color */
  --module-title-text-color: white; /* define secondary color */
  --body-background-color: white;
}

body {
  font-family: var(--font-family) !important;

  /*background: -webkit-linear-gradient(
    to right,
    var(--body-background-color),
    var(--module-lable-color)
  );  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(
    to right,
    var(--field-label-color),
    var(--module-lable-color)
  );  W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background-color: var(--body-background-color) !important; */
}
.main-body {
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}
.module-title {
  background-color: var(--module-lable-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}
.module-field {
  background-color: var(--field-label-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}
.title-label-text-color {
  color: var(--module-title-text-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}
.field-label-text-color {
  color: var(--field-label-text-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}

.content {
  background: #fff;
  padding: 20px !important;
  margin: 20px auto !important;
  border-radius: 4px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
}
.help {
  padding: 10px;
  font-size: 12px !important;
  background: #fff;
  margin: 20px auto !important;
  border-radius: 4px;
  /* box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15); */
}
.help table {
  font-size: 12px;
}
.help table td {
  padding: 5px !important;
}

.canvas-container-main {
  width: 100%;
  height: 300px;
  border: 1px solid #ccc;
}

.debug {
  font-weight: bold;
  padding: 10px;
  /* background: #fff; */
  width: 80%;
  /* border-radius: 4px; */
  /* margin: 20px auto !important; */
  /* box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15); */
  color: #0165af;
}
.projects-wrapper {
  box-shadow: -2px 1px 10px 4px var(--field-label-color);
  -webkit-box-shadow: -2px 1px 10px 4px var(--field-label-color);
  -moz-box-shadow: -2px 1px 10px 4px var(--field-label-color);
}
.table-back-wrapper {
  box-shadow: -2px 1px 10px 4px var(--module-lable-color) !important;
  -webkit-box-shadow: -2px 1px 10px 4px var(--field-label-color);
  -moz-box-shadow: -2px 1px 10px 4px var(--field-label-color);
  font-family: var(--font-family) !important;
  font-size: var(--font-size) !important;
  font-weight: var(--font-weight) !important;
  font-style: var(--font-style) !important;
  text-decoration: var(--text-decoration) !important;
}
.center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.recordingIcon {
  /* float: right; */
  font-size: 20px;
}
.on {
  color: #8080ff !important;
}
.off {
  color: #f85e5e !important;
}
body {
  background-color: #47d1da;
}
p {
  color: white;
  font: 300 4em/150% Impact;
  text-align: center;
}
.ant-divider {
  margin: 5px 0px !important;
}

.show {
  visibility: visible;
}
.hide {
  visibility: hidden;
}

#recordingAnimation {
  padding-left: 10px;
}
#recordingAnimation span {
  display: inline-block !important;
  background-color: #ccc;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  margin-right: 7px;
  animation-name: blink;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
#recordingAnimation span:nth-child(2) {
  animation-delay: 0.1s;
}
#recordingAnimation span:nth-child(3) {
  animation-delay: 0.2s;
}
#recordingAnimation span:nth-child(4) {
  animation-delay: 0.3s;
}
@keyframes blink {
  0% {
    background-color: rgba(195, 205, 213, 0.2);
  }
  20% {
    background-color: rgba(90, 178, 239, 1);
    transform: scale(1.2);
  }
  100% {
    background-color: rgba(195, 205, 213, 0.2);
  }
}

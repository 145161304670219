.bg-gray {
  background-color: #e9ecef;
}

.new-line {
  overflow: hidden;
}

/* .hello {
  white-space: pre-wrap;
} */
